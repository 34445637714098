<template>
  <!--页面头部图片+文字-->
  <div class="top-view">
    <img class="top-view__bg" :src="img" alt="">
    <normal-title :cn="cn" :en="en"/>
  </div>
</template>

<script>
  import NormalTitle from "./NormalTitle"
  export default {
    name: "TopView",
    components: {
      NormalTitle
    },
    props: {
      cn: String,
      en: String,
      img: String
    },
  }
</script>

<style lang="scss" scoped>
  .top-view {
    text-align: left;
    position: relative;
    .normal-title {
      color: #ffffff;
      position: absolute;
      left: 15%;
      bottom: 7%;
    }
  }
  .top-view__mask {
    width: 100%;
    height: 700px;
    background: #000000;
    position: absolute;
    top: 0;
    left: 0;
  }
  .top-view__bg {
    width: 100%;
    display: block;
  }
</style>
