<template>
  <div class="normal-title">
    <h2 class="normal-title__cn">{{cn}}</h2>
    <h2 class="normal-title__en">{{en}}</h2>
  </div>
</template>

<script>
  export default {
    name: "NormalTitle",
    props: {
      cn: String,
      en: String
    }

  }
</script>

<style lang="scss" scoped>
.normal-title__cn {
  font-size: 36px;
  line-height: 36px;
  margin-bottom: 15px;
}
.normal-title__en {
  font-size: 24px;
  line-height: 24px;
  font-weight: normal;
}
</style>
