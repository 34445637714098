<template>
  <main ref="root">
    <top-view cn="新闻中心" en="News center" :img="require('../../assets/img/bg_09@2x.jpg')"/>
      <v-skeleton-loader type="image, paragraph"
                         tile
                         class="loader"
                         v-if="loading"
                         v-for="n in 3" :key="n"></v-skeleton-loader>
      <ul class="news-view__container" v-if="asyncNews.length">
        <li class="news-view__item" v-for="item in asyncNews" :key="item.title">
          <a :href="item.link" target="_blank">
            <div class="news-view__img">
              <img :src="item.image" :alt="item.title">
            </div>
            <div class="news-view__text">
              <h2 :title="item.title" :class="{'news-view__title--special': /^【/.test(item.title)}">{{item.title}}</h2>
              <p>{{`日期: ${item.relTime}`}}</p>
              <p>{{item.depict}}</p>
            </div>
            <img class="news-view__go" src="../../assets/img/icon_go@2x.png" alt="">
          </a>
        </li>
      </ul>
    <v-pagination :length="length" v-model="page" class="pagination" @input="pageChange"></v-pagination>
  </main>
</template>

<script>
  import news from "../../assets/js/news"
  import TopView from "../../components/common/TopView"
  import TimeFormatter from "../../utils/timeFormatter"
  export default {
    name: "news",
    components: {
      TopView,
    },
    metaInfo: {
      title: '乐享无限官网-新闻中心',
      meta: [
        {
          name: 'keyWords',
          content: '乐享无限新闻中心,乐享无限动态,乐享无限公众号,乐享无限官网,乐享无限综合性自我增值平台,智菁通,财务规划',
        },
        {
          name: 'description',
          content: '乐享无限新闻中心为你提供最新的财务规划和理财相关的新闻与活动消息,关注公众号"乐享无限综合性自我增值平台"可以了解更多新闻。'
        }
      ]
    },
    data() {
      return {
        news: news,
        asyncNews: [],
        page: 1,
        length: 3,
        loading: true
      }
    },
    methods: {
      // 需要加载中特效
      // 切换页面
      pageChange(page) {
        this.getNews(page)
      },
      getNews(page) {
        this.$axios.news.getNews({
          sort: 'relTime',
          types: 'lxwx',
          page: page - 1,
          limit: 5
        }).then(res => {
          this.asyncNews = res.data.list.map(item => {
            item.relTime = new TimeFormatter(item.relTime).getMacroTime()
            return item
          })
        })
      }
    },
    created() {
      this.$axios.news.getNews({
        sort: 'relTime',
        types: 'lxwx',
        page: 0,
        limit: 5
      }).then(res => {
        this.length = Math.ceil(res.data.total / 5)
        this.asyncNews = res.data.list.map(item => {
          item.relTime = new TimeFormatter(item.relTime).getMacroTime()
          return item
        })
        this.$nextTick(() => {
          this.loading = false
        })
      })
    }
  }
</script>

<style lang="scss" scoped>
  // 分页
  ::v-deep .primary {
    background-color: #333641!important;
  }
  .pagination {
    margin-bottom: 40px;
  }
  // 骨架
  .loader {
    max-width: 1340px;
    width: 90%;
    box-shadow: $shadow;
    height: 200px;
    margin: 50px auto;
    display: flex;
    &:nth-child(2) {
      margin-top: 80px;
    }
  }
  ::v-deep .v-skeleton-loader__image {
    width: 300px;
  }
  ::v-deep .v-skeleton-loader__paragraph {
    margin-left: 50px;
    margin-top: 28px;
    .v-skeleton-loader__text {
      height: 18px;
      &:first-child {
        height: 36px;
        width: 80%;
      }
      &:nth-child(2) {
        width: 200px;
        margin: 20px 0;
      }
      &:last-child {
        width: 80%;
      }
    }
  }

// 包含所有新闻 - ul
.news-view__container {
  min-height: 160px;
  margin: 80px auto;
}
// 单个新闻 - li
.news-view__item {
  max-width: 1340px;
  width: 90%;
  position: relative;
  @include clearfix;
  box-shadow: $shadow;
  margin: 50px auto;
  &:last-child {
    margin: 0 auto;
  }
}
.news-view__img {
  width: 300px;
  height: 200px;
  background: #000000;
  float: left;
  border-right: 10px solid #f39800;
  position: relative;
  overflow: hidden;
  img {
    position: absolute;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}
.news-view__text {
  float: left;
  width: calc(100% - 320px - 50px);
  height: 200px;
  color: #333333;
  padding-left: 50px;
  box-sizing: border-box;
  h2 {
    margin: 28px 0 16px;
    font-size: 24px;
    // 防止折行
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }
  p {
    &:nth-child(2) {
      margin-bottom: 28px;
      font-size: 18px;
      line-height: 18px;
    }
    &:nth-child(3) {
      font-size: 16px;
      width: calc(100% - 20px);
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
}
.news-view__go {
  position: absolute;
  right: 25px;
  bottom: 20px;
}

// 以[开头的标题
.news-view__title--special {
  text-indent: -12px;
}
</style>
